
.user-header {
    .message {
        padding-left: 30px;
    }

    .logout {
        float: right;
        padding-right: 10px;
    }
}

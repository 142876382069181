
.global-footer {
    width: 100%;
    bottom: 0;
    text-align: center;
    padding-top: 100px;

    .copyright {
        .text {

            color: #808080;
            font-size: 12px;
            text-decoration: underline !important;
        }

        .copyright-text {
            font-size: 12px;
            color: #808080;
        }
    }
}

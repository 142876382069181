
.login {
    width: 100%;
    height: 100%;
    position: fixed;
    background-image: url("../../assets/login.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .content {

        .title {
            font-size: 50px;
            padding-top: 50px;
            text-align: center;
            color: bisque;
        }

        .content-login {
            text-align: center;

            .userName {
                width: 500px;
            }

            .password {

                width: 500px;
            }
        }
    }
}


.logo-container {
        .logo {
            height: 80px;
            text-align: center;
            background-image: url('~@/assets/navbar.jpeg');

            .logo-normal {
                height: 80px;
                text-align: center;
            }
        }
        .mobile-logo {
            height: 80px;
            text-align: center;
            .logo-mobile{
                height: 80px;
                text-align: center;
            }
        }
    }


body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  .app {
    width: 100%;
    height: 100%;
  }
}

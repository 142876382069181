
.select-version-content{
    ul,li {
            list-style: none;
            margin: 0;
            padding: 0;
        }
    .top-title {
                text-align: center;
                font-size: 22px;
                padding: 36px 0 30px 0;
                font-weight: 600;
                line-height: 42px;
            }
            .select-version {
                display: flex;
                .version {
                    border-radius: 4px;
                    position: relative;
                    margin-right: 36px;
                    height: 97px;
                    cursor: pointer;
                    a {
                        position: absolute;
                        left: 0;
                        top: 0;
                        background: url("~@/assets/blackdot.png") repeat;
                        width: 215px;
                        height: 97px;
                        text-align: center;
                        span {
                            width: 58px;
                            height: 58px;
                            border-radius: 58px;
                            background-color: #fff;
                            display: inline-block;
                            text-align: center;
                            margin-top: 18px;
                        }
                    }
                    > img {
                        width: 214px;
                        height: auto;
                    }
                }
            }

            .version-tip {
                .version-dec {
                    color: #1272D7;
                    line-height: 48px;
                    background-color: #F0F7FF;
                    box-sizing: border-box;
                    padding: 0 20px;
                    margin-top: 22px;
                    position: relative;
                    .sanjiao {
                        position: absolute;
                        left: 100px;
                        top: -10px;
                        width: 0;
                        height: 0;
                        border-bottom: 10px solid #F0F7FF;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                    }
                }
            }
           
}


.pc-client-upload {
    background-color: #F8F8F8;

    .content {
        max-width: 1200px;
        background: #fff;
        margin: 20px auto;
        padding: 0 116px 60px 116px;

        .content-title {
            margin-top: 38px;
            text-align: left;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 22px;
        }

        .add-card {
            text-align: right;
            margin-bottom: 10px;
            margin-top: -10px;

            a {
                cursor: pointer;
            }
        }

        ul,
        li {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        .top-contet {
            .top-title {
                text-align: center;
                font-size: 22px;
                padding: 36px 0 30px 0;
                font-weight: 600;
                line-height: 42px;
            }
            
        }

        /deep/.ant-col-10 {
            width: 100%;

            .ant-input-wrapper {
                line-height: 46px;
                border: 1px solid #D2DBE6;
                overflow: hidden;
                position: relative;

                .ant-input {
                    line-height: 46px;
                    height: 46px;
                    border: 0 none;
                    box-sizing: border-box;
                    padding: 0 10px 0 10px;

                    &:focus {
                        box-shadow: none;
                    }
                }

                .ant-input-group-addon {
                    width: 80px;
                    border: none;
                    background: #fff;
                }

            }
        }

        .file-upload {
            ul {
                li {
                    display: inline-block;
                    width: 140px;
                    height: 46px;
                    text-align: center;
                    line-height: 45px;
                    border-left: 1px solid #D2DBE6;
                    border-top: 1px solid #D2DBE6;
                    border-right: 1px solid #D2DBE6;
                    position: relative;
                    cursor: pointer;

                    &::after {
                        content: "";
                        width: 140px;
                        height: 3px;
                        position: absolute;
                        left: 0;
                        top: -1px;
                        background: #FF6565;
                    }

                    .whiteline {
                        content: "";
                        width: 140px;
                        height: 1px;
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        background: #fff;
                        z-index: 99;
                    }
                }
            }

            .upload-box {
                height: 300px;
                box-sizing: border-box;
                border: 1px solid #D2DBE6;
                text-align: center;
                vertical-align: middle;

                .pic {
                    margin-top: 15px;

                    img {
                        height: 100px;
                        margin: 0 auto 10px auto;
                    }
                }

                .title {
                    height: 40px;
                    line-height: 24px;
                }

                /deep/.ant-btn {
                    border: 1px solid rgba(249, 65, 83, 1);
                    background: linear-gradient(315deg, rgba(249, 65, 83, 1) 0%, rgba(254, 105, 120, 1) 100%);
                    color: #FFF;
                    width: 120px;
                    height: 35px;
                    line-height: 35px;
                    border-radius: 4px;
                    font-size: 14px;
                }

                /deep/.ant-upload-list-item-info {
                    .ant-upload-list-item-name {
                        width: auto;
                    }

                    .ant-upload-list-item-card-actions {
                        display: none;
                    }
                }
            }
        }

    }

    .operation {
        padding-top: 35px;

        /deep/.ant-btn-primary {
            background-color: #FF311C;
            line-height: 48px;
            font-size: 16px;
            color: #fff;
            border-radius: 2px;
            height: 48px;
            width: 120px;
            border: none;

            .anticon-upload {
                display: none
            }

            span {
                margin-left: 0;
            }
        }
    }

    .addition-operation {

    }

}


.order-add {
    .content {
        .main {
            .dragger {
                padding-top: 30px;
                height: 200px;
                width: 500px;
            }

            .upload-button {
                padding-top: 40px;
                text-align: center;
                // float: right;
            }
        }
    }
}

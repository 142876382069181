
.client-upload {
    .content {
        padding-top: 20px;
    }

    .add-card {
            text-align: right;
            margin-bottom: 10px;
            margin-top: -10px;

            a {
                cursor: pointer;
            }
        }

    .operation {
        padding-top: 20px;
        text-align: center;
    }

    .addition-operation {
        // text-align: center;
    }

}


.client-download {
    .content-pc {
        //padding-top: 20px;
        padding: 30px 116px 60px 116px;
    }

    .content{
        padding-top: 20px;
    }

    .search {
        .search-button {
            padding-top: 10px;
            padding-right: 20px;
            float: right;
        }
    }

    .message {
        padding-top: 20px;
    }

    .result-table {
        margin-top: 70px;
    }

    .operation {
        padding-top: 30px;
        padding-right: 20px;
        float: right;

        .delete-operation {
            padding-top: 20px;
        }

        .clear-operation {
            padding-top: 20px;
        }
    }

    .footer {
        bottom: 0;
        margin-top: 300px;
    }

}


.client-file-manage-header {
    background: #fff;
    .content-header {
        display: flex;
        justify-content: space-between;
        max-width: 1200px;
        margin: auto;
    }
    .memu-btn {
        height: 80px;
        line-height: 80px;
        cursor: pointer;
        padding: 0 40px;

        span {
            padding: 0 16px;
            font-weight: 600;
            color: #333;
        }
        span.active {
            color: #FF311C;
        }
    }
    .logo-container {
        
    }

    .ads-container {
        
    }


}


.user-main {
    .content {
        .main {
            .top-control {
                padding-top: 20px;

                .selection {}

                .time-period {
                    padding-top: 10px;
                }

                .input {
                    padding-top: 10px;
                }

                .search {
                    padding-top: 10px;
                    padding-bottom: 20px;
                }

            }

            .table {
                padding-top: 30px;
            }

            .pagination {
                padding-top: 30px;
                text-align: center;
            }

        }
    }
}
